<template>
  <div class="flexdc flex1" style="height:100%">
    <div class="operationControl flexdc operationControlCurr" style="align-items: flex-start;">
      <div class="searchbox">
        <div>
          <div title="课件名称" class="searchboxItem" style="display:flex;align-items: center;">
            <span class="itemLabel"
              style="min-width: 5rem; text-align: right; font-size: 14px;padding: 0 0.5rem;">课件名称:</span>
            <el-input v-model="queryStr" type="text" size="small" clearable placeholder="请输入课件名称" />
          </div>
          <div title="课件来源" class="searchboxItem" style="display:flex;align-items: center;" v-if="pdPpointSource">
            <span class="itemLabel"
              style="min-width: 5rem; text-align: right; font-size: 14px;padding: 0 0.5rem;">课件来源:</span>
            <el-select v-model="ruleForm.source" placeholder="请选择课程来源" clearable size="small">
              <el-option label="自有" value="10"></el-option>
              <el-option label="平台" value="20"></el-option>
            </el-select>
          </div>
          <div title="有无弹题" class="searchboxItem" style="display:flex;align-items: center;">
            <span class="itemLabel"
              style="min-width: 5rem; text-align: right; font-size: 14px;padding: 0 0.5rem;">有无弹题:</span>
            <el-select v-model="ruleForm.hasPop" placeholder="请选择有无弹题" clearable size="small">
              <el-option label="有" :value="true"></el-option>
              <el-option label="无" :value="false"></el-option>
            </el-select>
          </div>
          <transition name="el-zoom-in-center">
            <el-button class="bgc-bv" round @click="getData()" size="small"
              v-show="dialogVisible == false">查询</el-button>
          </transition>
          <div style="margin-left:10px;">
            <el-button @click="search()" size="small" type="text">
              高级搜索
              <i :class="dialogVisible ? 'el-icon-top' : 'el-icon-bottom'"></i>
            </el-button>
          </div>
        </div>
        <div class="btnBox">
          <el-button type="primary" class="bgc-bv" :disabled="userJson.tempAccount" round
            @click="handleAddCourseware()">上传新课件</el-button>
          <el-button type="primary" class="bgc-bv" :disabled="userJson.tempAccount" round
            @click="handleAddExternalCourseware()">上传外部课件</el-button>
          <el-button type="primary" class="bgc-bv" :disabled="userJson.tempAccount" round
            @click="handleAddExistingCourseware()">添加已上传课件</el-button>
          <el-button class="bgc-bv" round @click="open" size="small">自定义表头</el-button>
        </div>
      </div>
      <el-collapse-transition>
        <div class="searchbox" style="margin-top:10px" v-show="dialogVisible == true">
          <el-form label-width="90px" ref="ruleForm" :model="ruleForm">
            <el-form-item label="审核状态:" prop="Auditstatus">
              <el-select v-model="ruleForm.Auditstatus" placeholder="请选择审核状态" clearable size="small">
                <el-option label="未提审" value="10"></el-option>
                <el-option label="待审核" value="20"></el-option>
                <el-option label="已通过" value="30"></el-option>
                <el-option label="已驳回" value="40"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否有纲要:" prop="hasKpointOutline" label-width="100px">
              <el-select v-model="ruleForm.hasKpointOutline" placeholder="请选择是否有纲" clearable size="small">
                <el-option label="全部" value=""></el-option>
                <el-option label="无纲要" :value="false"></el-option>
                <el-option label="有纲要" :value="true"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销售状态:" prop="Salesstatus" style="margin-left:10px">
              <el-select v-model="ruleForm.Salesstatus" placeholder="请选择销售状态" clearable size="small">
                <el-option label="在售" value="20"></el-option>
                <el-option label="未售" value="10"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="培训类型:" prop="Trainingtype">
              <tree ref="tree" @eventBtn="childBack" :ruleForm="ruleForm" size="small" :modal="false"
                @clearParams="clearParams" typeStu />
            </el-form-item>
            <el-form-item label="资源提供者:" prop="compOwnerId" v-if="pdPpointSource">
              <el-select size="small" v-model="ruleForm.compOwnerId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-left:10px;" class="btns">
              <el-button class="bgc-bv" round @click="getData()" size="small">查询</el-button>
              <el-button @click="cancleForm()" type="text" style="padding:0">清空搜索条件</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-transition>
    </div>
    <div class="framePage-scroll">
      <!-- <div class="ovy-a"> -->
      <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
        style="width: 100%" :header-cell-style="tableHeader" stripe v-if="showtable">
        <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
        <el-table-column label="课件名称" min-width="260" align="left" fixed show-overflow-tooltip prop="kpointName" />
        <template v-if="tableHaed.length">
          <el-table-column v-for="(el, index) in tableHaed" :key="index" :label="el.label" :align="el.textAlign"
            show-overflow-tooltip :min-width="el.width">
            <!-- <template slot-scope="scope">
              {{
              getValue(el,scope.row)
              }}
              
            </template> -->
            <template slot-scope="scope">
              <span :class="el.label == '销售状态' &&
              scope.row.salesState == '10' &&
              scope.row.kpointOrigin == '10'
              ? 'textRed'
              : ''
              ">{{ getValue(el, scope.row) }}</span>
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" align="center" width="320px" fixed="right">
          <div slot-scope="scope" class="df">
            <el-button style="padding:0 10px" type="text" size="mini" @click="handleEdit(scope.row)">查看</el-button>
            <el-button style="padding:0 5px" type="text" size="mini" :disabled="userJson.tempAccount"
              @click="handleDel(scope.row.kpointId)" v-if="scope.row.kpointSource != '25'">删除</el-button>
            <el-button style="padding:0 5px" type="text" size="mini" :disabled="userJson.tempAccount"
              v-show="scope.row.kpointOrigin != '20' && scope.row.kpointSource != '25'"
              @click="handleCopy(scope.row.kpointId, scope.row.createId)">复制课件</el-button>
            <el-button style="padding:0 5px" type="text" size="mini" :disabled="userJson.tempAccount"
              @click="handleShowEvaluate(scope.row.kpointId)">查看评价</el-button>
            <el-button style="padding:0 5px;color:red" type="text" size="mini" :disabled="userJson.tempAccount" v-if="scope.row.kpointOrigin != '20' && scope.row.salesState == '10' && scope.row.kpointSource != '25'
              " @click="shelf(scope.row.kpointId)">上架</el-button>
            <el-button style="padding:0 5px" type="text" size="mini" :disabled="userJson.tempAccount"
              @click="Dismount(scope.row.kpointId)" v-if="scope.row.kpointOrigin != '20' && scope.row.salesState != '10' && scope.row.kpointSource != '25'
              ">下架</el-button>
            <el-button style="padding:0 5px" v-if="scope.row.auditState !== '30' && scope.row.kpointSource != '25'"
              type="text" size="mini" :disabled="userJson.tempAccount" @click="
              goDisinfectionRecord(scope.row.kpointId, scope.row.auditState)
              ">{{
              scope.row.auditState === "20" ? "撤销" : "提交"
            }}审核</el-button>
            <el-button style="padding:0 5px" type="text" size="mini" :disabled="userJson.tempAccount"
              @click="elastic(scope.row.kpointId)" v-if="scope.row.kpointSource != '25'">弹题配置</el-button>
          </div>
        </el-table-column>
        <Empty slot="empty" />
      </el-table>
      <!-- </div> -->
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog title="自定义表头" :visible.sync="tableHeadDialog" width="30%" class="custom" @close="cencal">
      <p style="padding-left:20px;margin-bottom:10px;">
        勾选需要显示在列表上的项，可拖动调整顺序
      </p>
      <div>
        <el-tree ref="headTree" :data="treeDataCopy" default-expand-all draggable :allow-drop="allowDrop"
          :allow-drag="allowDrag">
          <template slot-scope="{ node, data }">
            <el-checkbox @change="(val) => {
              handleCheckChange(val, data);
            }
              " v-model="data.checked">{{ node.label }}</el-checkbox>
          </template>
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cencal">取 消</el-button>
        <el-button class="bgc-bv" @click="resetHeadOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      showtable: false,
      queryStr: "",
      dialogVisible: false,
      ruleForm: {
        source: "",
        Auditstatus: "",
        hasKpointOutline: "",
        Salesstatus: "",
        Trainingtype: "",
        compOwnerId: "",
      },
      CompanyList: [], // 资源提供者
      activeName: "first",
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      params: {}, // 培训类型参数
      // 自定义表头
      tableHeadDialog: false, //
      treeData: [
        {
          label: "培训类型", //表头名称
          propName: "trainTypeNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 1,
          id: "2",
          checked: true,
        },
        {
          label: "行业类型", //表头名称
          propName: "industryNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 2,
          id: "2",
          checked: true,
        },
        {
          label: "岗位类型", //表头名称
          propName: "postName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 3,
          id: "3",
          checked: true,
        },
        {
          label: "职业/工种", //表头名称
          propName: "occupationNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 4,
          id: "4",
          checked: true,
        },
        {
          label: "培训等级", //表头名称
          propName: "trainLevelName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 5,
          id: "5",
          checked: true,
        },
        {
          label: "课件类型", //表头名称
          propName: "kpointType", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "RESOURCETYPE", //字典对应key
          width: "120", // 列的宽
          order: 6,
          id: "6",
          checked: true,
        },
        {
          label: "时长", //表头名称
          propName: "kpointDuration", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "custom", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 7,
          id: "7",
          checked: true,
        },
        {
          label: "讲师姓名", //表头名称
          propName: "teacherName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 8,
          id: "8",
          checked: true,
        },
        {
          label: "资质类型", //表头名称
          propName: "qualificationType", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "180", // 列的宽
          order: 9,
          id: "9",
          checked: true,
        },
        {
          label: "证书编号", //表头名称
          propName: "certificateNo", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "180", // 列的宽
          order: 10,
          id: "10",
          checked: true,
        },
        {
          label: "审核状态", //表头名称
          propName: "auditState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "AUDITSTATE", //字典对应key
          width: "120", // 列的宽
          order: 11,
          id: "11",
          checked: true,
        },
        {
          label: "课件来源", //表头名称
          propName: "kpointOrigin", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "COURSEORGIN", //字典对应key
          width: "100", // 列的宽
          order: 12,
          id: "12",
          checked: true,
        },
        {
          label: "资源提供者", //表头名称
          propName: "compOwnerName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 16,
          id: "16",
          checked: true,
        },
        {
          label: "数据来源", //表头名称
          propName: "kpointSource", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "KPOINT_SOURCE", //字典对应key
          width: "100", // 列的宽
          order: 13,
          id: "13",
          checked: true,
        },
        {
          label: "销售状态", //表头名称
          propName: "salesState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "SELLSTATE", //字典对应key
          width: "120", // 列的宽
          order: 14,
          id: "14",
          checked: true,
        },
        {
          label: "有无弹题", //表头名称
          propName: "hasPop", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "boolean", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 15,
          id: "15",
          checked: true,
        },
        // {
        //   label: "有无纲要", //表头名称
        //   propName: "hasKpointOutline", //后台对应字段名称
        //   textAlign: "left", //文字对齐方式e
        //   renderType: "boolean", //渲染类型
        //   timeType: "", //时间字段的渲染类型
        //   dictionaries: "", //字典对应key
        //   width: "120", // 列的宽
        //   order: 15,
        //   id: "15",
        //   checked: true,
        // },
      ],
      tableHaed: [],
      treeDataCopy: [],
    };
  },
  computed: {
    ...mapGetters({
      userJson: "getUser"
    }),
     // 判断列表是否显示数据来源
     pdPpointSource() {
      // 北京晟融机构id;10951，10982 联安众id;的显示数据来源列
      let compIdArr = ["10981", "10951", "10982"]
      return compIdArr.includes(this.userJson.compId)
    },
  },
  created() {
    this.getTableHeight();
    this.getTableHead();
  },
  methods: {
    // 根据字段转换页面展示的内容
    getValue(el, row) {
      // 状态
      if (el.renderType == "status") {
        if (el.propName == "auditState") {
          if (row.auditState == "30") {
            return "已通过";
          } else if (row.auditState == "40") {
            return "已驳回";
          } else {
            return this.$setDictionary(el.dictionaries, row[el.propName]);
          }
        } else if (el.propName == "salesState") {
          if (row.kpointOrigin == "20") {
            return "--";
          } else {
            return this.$setDictionary(el.dictionaries, row.salesState);
          }
        } else {
          return this.$setDictionary(el.dictionaries, row[el.propName]);
        }
      } else if (el.renderType == "time") {
        // 时间
        if (el.timeType == "moment") {
          return row[el.propName] ? this.$moment(row[el.propName]).format("YYYY-MM-DD HH:mm:ss") : "--";
        } else if (el.timeType == "custom") {
          return this.getTime(row[el.propName]);
        } else {
          return row[el.propName];
        }
      } else if (el.renderType == "boolean") {
        //  return row[el.propName] == "hasPop";
        if (row[el.propName]) {
          return "有";
        } else {
          return "无";
        }
      } else {
        // 其他
        return row[el.propName] || "--";
      }
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 自定义确定
    resetHeadOk() {
      let list = [];
      let saveData = [];
      this.treeDataCopy.forEach((el, index) => {
        saveData.push({ id: el.id, order: index, checked: el.checked });
        if (el.checked) {
          list.push(el);
        }
      });
      this.tableHaed = list;
      this.tableHeadDialog = false;
      this.$post("/sys/admin/savePageConfig", {
        configInfo: JSON.stringify(saveData),
        pageName: "myCourseware",
      }).then((res) => {
        this.getTableHead();
      });
    },
    open() {
      this.tableHeadDialog = true;
      this.handle();
    },
    cencal() {
      this.tableHeadDialog = false;
    },
    getTableHead() {
      this.$post("/sys/admin/findAdminConfig", {
        pageName: "myCourseware",
      })
        .then((res) => {
          if (res.status == 0) {
            const data = res.data || {};
            let list = [];
            if (data.configInfo && data.configInfo != "") {
              list = JSON.parse(data.configInfo);
            }
            if (list.length) {
              list.map((el) => {
                this.treeData.forEach((it, index) => {
                  if (el.id == it.id) {
                    it.checked = el.checked;
                    it.order = el.order;
                  }
                });
              });
              this.treeData.sort((a, b) => {
                return a.order - b.order;
              });
              this.handle();
            } else {
              this.handle();
            }
          } else {
            this.handle();
          }
        })
        .catch((err) => {
          this.tableHaed = [...this.treeData];
        });
    },
    handle() {
      // 北京晟融机构id;10951，10982 联安众id;的显示数据来源列
      let compIdArr = ["10981", "10951", "10982"]
      if (!compIdArr.includes(this.userJson.compId)) {
        for (let i = 0; i < this.treeData.length; i++) {
          if (this.treeData[i].id == '13') {
            this.treeData.splice(i, 1);
          }
        }
      }
      this.tableHaed = [];
      this.treeDataCopy = [];
      this.treeData.forEach((el) => {
        this.treeDataCopy.push(Object.assign({}, el));
      });
      this.treeDataCopy.forEach((el) => {
        if (el.checked) {
          this.tableHaed.push(el);
        }
      });
      this.showtable = true;
    },
    // 判断拖拽的位置
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 节点是否可以拖拽
    allowDrag(draggingNode) {
      if (draggingNode.label == "课程名称") {
        return false;
      }
      return true;
    },
    handleCheckChange(val, data) { },
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    init() {
      this.getTableHeight();
      this.getData(-1);
    },
    getTime(val) {
      let m = parseInt(val / 60);
      let s = val % 60;
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return m + ":" + s;
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        hasPop: this.ruleForm.hasPop,
        hideRrs: false
      };
      if (this.queryStr) {
        params.kpointName = this.queryStr;
      }
      if (this.ruleForm.Auditstatus) {
        params.auditState = this.ruleForm.Auditstatus;
      }
      if (typeof this.ruleForm.hasKpointOutline == 'boolean') {
        params.hasKpointOutline = this.ruleForm.hasKpointOutline;
      }
      if (this.ruleForm.source) {
        params.kpointOrigin = this.ruleForm.source;
      }
      if (this.ruleForm.Salesstatus) {
        params.salesState = this.ruleForm.Salesstatus;
      }
      if (this.ruleForm.compOwnerId) {
        params.compOwnerId = this.ruleForm.compOwnerId;
      }
      this.doFetch({
        url: "/biz/courseware/listPage",
        params,
        pageNum,

      });
    },
    // 上传课件
    handleAddCourseware() {
      this.$router.push({ path: "/web/AddCourseware" });
    },
    // 上传外部课件
    handleAddExternalCourseware() {
      this.$router.push({ path: "/web/AddExternalCourseware" });
    },
    // 上传已有课件
    handleAddExistingCourseware() {
      this.$router.push({ path: "/web/AddExistingCourseware" });
    },
    // 删除
    handleDel(id) {
      this.doDel({
        title: "删除",
        url: "/biz/courseware/modifyDeleted",
        msg:
          "你确定要删除该课件吗？课程中关联了该课件，课程中的课件也会被同时删除。",
        ps: {
          type: "post",
          data: {
            kpointId: id,
            deleted: 1,
          },
        },
      });
    },
    handleShowEvaluate() {
      this.$message({
        message: "程序员正在努力开发,敬请期待...",
        type: "warning",
        duration: 1000,
      });
    },
    // 上架
    shelf(id) {
      this.doDel({
        title: "上架",
        url: "/biz/courseware/sellStateSubmit",
        msg: "你确定要将该课件发布到平台市场吗？",
        ps: {
          type: "post",
          data: { kpointId: id, sellState: "20" },
        },
      });
    },
    Dismount(id) {
      this.doDel({
        title: "下架",
        url: "/biz/courseware/sellStateSubmit",
        msg: "你确定要将该课件从平台市场下架吗？",
        ps: {
          type: "post",
          data: { kpointId: id, sellState: "10" },
        },
      });
    },
    // 提/撤 审核
    goDisinfectionRecord(kpointId, stu) {
      if (stu === "20") {
        this.doDel({
          title: "撤销审核",
          url: "/biz/courseware/auditRevokeSubmit ",
          msg: "该课件已经提交审核，是否撤销审核？",
          ps: {
            type: "post",
            data: { kpointId: kpointId, auditState: "10" },
          },
        });
      } else {
        this.doDel({
          title: "提交审核",
          url: "/biz/courseware/auditSubmit",
          msg: "课件只有审核通过后才可以使用，您是否提交课件审核？",
          ps: {
            type: "post",
            data: { kpointId: kpointId, auditState: "20" },
          },
        });
      }
    },
    // 高级搜索弹窗
    search() {
      this.dialogVisible = !this.dialogVisible;
      this.getTableHeight();
    },
    //清空搜索条件
    cancleForm() {
      // this.$refs.tree.clear(); // 高级搜索关闭，清空培训类型数据
      this.ruleForm = {
        source: "",
        Auditstatus: "",
        Salesstatus: "",
        Trainingtype: "",
        Creattime: "",
      };
      this.queryStr = "";
      this.params = {};
    },
    // 修改课件
    handleEdit(row) {
      if (row.kpointSource != "30") {
        this.$router.push({
          path: "/web/viewKpoint",
          query: { kpointId: row.kpointId, auditState: row.auditState },
        });
      } else {
        this.$router.push({
          path: "/web/AddExternalCourseware",
          query: { kpointId: row.kpointId, auditState: row.auditState },
        });
      }
    },
    // 复制课件
    handleCopy(kpointId, createId) {
      this.$router.push({
        path: "/web/viewKpoint",
        query: { kpointId, createId, stu: "copy" },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.dialogVisible == false
            ? 90 + 0.675 * 16 + 1
            : 120 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    /* 弹题配置 */
    elastic(kpointId) {
      this.$router.push({
        path: "/web/elasticList",
        query: { kpointId },
      });
    },
  },
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "\e6df" !important;
    }
  }

  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;
    }
  }
}

.operationControlCurr {
  >div {
    width: 100%;
    justify-content: space-between;
  }

  .el-form {
    display: flex;

    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }

    .el-form-item {
      margin-bottom: 0;
    }

    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}

.textRed {
  color: red;
}
</style>
