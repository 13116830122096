<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">我的课程/课件</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabChange">
          <el-tab-pane label="我的课程" name="first">
            <Curriculum ref="first" />
          </el-tab-pane>
          <el-tab-pane label="我的课件" name="second">
            <Courseware ref="second" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import Courseware from "@/views/resourse/myresourseList/Courseware.vue";
import Curriculum from "@/views/resourse/myresourseList/Curriculum.vue";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "myresourseList",
  components: {
    Courseware,
    Curriculum
  },
  mixins: [List],
  data() {
    return {
      activeName: "first"
    };
  },
  methods: {
    init() {
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
      } else {
        this.activeName = "first";
      }
    },
    tabChange(tab) {
      this.$refs[tab.name].queryStr = "";
      this.$refs[tab.name].getData();
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: function(route) {
      if(route.name !='myresourseList') return;
      if (route.params.refresh) {
        if (route.params.isAdd) {
          this.$refs[route.params.refName].cancleForm();
          this.$refs[route.params.refName].getData();
        } else {
          this.$refs[route.params.refName].getData(-1);
        }
      } else if(sessionStorage.getItem('refresh') !=0){
        sessionStorage.setItem('refresh',0);
        this.$refs['first'].cancleForm();
        this.$refs['first'].getData();
      }
    }
  }
};
</script>
<style lang="less"></style>
