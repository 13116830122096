<template>
  <div class="flexdc flex1" style="height: 100%">
    <div
      class="operationControl flexdc operationControlCurr"
      style="align-items: flex-start"
    >
      <div class="searchbox">
        <div>
          <div
            title="课程名称"
            class="searchboxItem"
            style="display: flex; align-items: center"
          >
            <span
              class="itemLabel"
              style="
                min-width: 5rem;
                text-align: right;
                font-size: 14px;
                padding: 0 0.5rem;
              "
              >课程名称:</span
            >
            <el-input
              v-model="queryStr"
              type="text"
              size="small"
              clearable
              placeholder="请输入课程名称"
            />
          </div>
          <transition name="el-zoom-in-center">
            <el-button
              class="bgc-bv"
              round
              @click="getData()"
              size="small"
              v-show="dialogVisible == false"
              >查询</el-button
            >
          </transition>
          <div style="margin-left: 10px">
            <el-button @click="search()" size="small" type="text">
              高级搜索
              <i
                :class="
                  dialogVisible == true ? 'el-icon-top' : 'el-icon-bottom'
                "
              ></i>
            </el-button>
          </div>
        </div>
        <div class="btnBox">
          <el-button class="bgc-bv" round @click="handleAdd()" size="small"  :disabled="userJson.tempAccount"
            >创建课程</el-button
          >
          <el-button class="bgc-bv" round @click="open" size="small"
            >自定义表头</el-button
          >
        </div>
      </div>
      <el-collapse-transition>
        <div
          class="searchbox"
          style="margin-top: 10px"
          v-show="dialogVisible == true"
        >
          <el-form label-width="80px" ref="ruleForm" :model="ruleForm">
            <el-form-item label="课程来源:" prop="source">
              <el-select
                v-model="ruleForm.source"
                placeholder="请选择课程来源"
                clearable
              >
                <el-option label="自有" value="10"></el-option>
                <el-option label="平台" value="20"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="审核状态:" prop="auditState">
              <el-select
                v-model="ruleForm.auditState"
                placeholder="请选择审核状态"
                clearable
              >
                <el-option label="未提审" value="10"></el-option>
                <el-option label="待审核" value="20"></el-option>
                <el-option label="已通过" value="30"></el-option>
                <el-option label="已驳回" value="40"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销售状态:" prop="salesState">
              <el-select
                v-model="ruleForm.salesState"
                placeholder="请选择销售状态"
                clearable
              >
                <el-option label="在售" value="20"></el-option>
                <el-option label="未售" value="10"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="培训类型:" prop="Trainingtype">
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                :modal="false"
                @clearParams="clearParams"
                typeStu
              />
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-transition>
      <el-collapse-transition>
        <div
          class="searchbox"
          style="margin-top: 8px"
          v-show="dialogVisible == true"
        >
          <el-form label-width="80px" ref="ruleForm" :model="ruleForm">
            <el-form-item label="创建时间:" prop="Creattime" class="Creattime">
              <el-date-picker
                v-model="ruleForm.Creattime"
                type="daterange"
                range-separator="至"
                value-format="timestamp"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item style="margin-left: 10px" class="btns">
              <el-button class="bgc-bv" round @click="getData()" size="small"
                >查询</el-button
              >
              <el-button @click="cancelForm()" type="text"
                >清空搜索条件</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-collapse-transition>
    </div>
    <div class="framePage-scroll">
      <el-table
        ref="multipleTable"
        :data="tableData"
        :height="tableHeight"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="tableHeader"
        stripe
        v-if="showtable"
      >
        <el-table-column
          label="序号"
          align="center"
          type="index"
          fixed
          :index="indexMethod"
        />
        <el-table-column
          label="课程名称"
          min-width="360"
          align="left"
          fixed
          prop="courseName"
        >
          <template slot-scope="scope">
            <div style="display: flex; align-items: center">
              <el-tooltip
                class="item"
                effect="dark"
                content="已被下架"
                placement="top"
              >
                <i
                  class="el-icon-warning-outline"
                  v-if="!scope.row.enableLook"
                  style="color: red; font-size: 0.9rem"
                ></i>
              </el-tooltip>
              {{ scope.row.courseName }}
            </div>
          </template>
        </el-table-column>
        <template v-if="tableHaed.length">
          <el-table-column
            v-for="(el, index) in tableHaed"
            :key="index"
            :label="el.label"
            :align="el.textAlign"
            show-overflow-tooltip
            :min-width="el.width"
          >
            <template slot-scope="scope">
              <span
                :class="
                  el.label == '销售状态' &&
                  scope.row.salesState == '10' &&
                  scope.row.courseOrigin == '10'
                    ? 'textRed'
                    : ''
                "
                >{{ getValue(el, scope.row) }}</span
              >
            </template>
          </el-table-column>
        </template>
        <el-table-column label="备注" show-overflow-tooltip align="left" width="200">
          <template slot-scope="scope">{{
              scope.row.remark || "--"
            }}</template>
        </el-table-column>
        <el-table-column
          label="操作"
          header-align="center"
          align="left"
          width="220px"
          fixed="right"
        >
          <div slot-scope="scope">
            <el-button
              style="padding: 0 5px"
              type="text"
              size="mini"
              :disabled="!scope.row.enableLook"
              @click="handleEdit(scope.row)"
              >查看</el-button
            >
            <el-button
              style="padding: 0 5px"
              type="text"
              size="mini"
              v-show="scope.row.courseSource != '25'"
              :disabled="userJson.tempAccount"
              @click="handleDel(scope.row.courseId)"
              >删除</el-button
            >
            <el-button
              style="padding: 0 5px"
              type="text"
              size="mini"
               :disabled="userJson.tempAccount"
              :class="scope.row.salesState === '10' ? 'textRed' : ''"
              v-show="scope.row.courseOrigin != '20' && scope.row.courseSource != '25'"
              @click="shelf(scope.row.courseId, scope.row.salesState)"
              >{{ scope.row.salesState === "10" ? "上" : "下" }}架</el-button
            >
            <el-button
              style="padding: 0 5px"
              type="text"
              size="mini"
               :disabled="userJson.tempAccount"
              v-show="scope.row.courseOrigin != '20' && scope.row.courseSource != '25'"
              @click="handleCopy(scope.row.courseId)"
              >复制课程</el-button
            >
            <el-button
              style="padding: 0 5px"
              v-if="scope.row.auditState !== '30'"
              type="text"
              size="mini"
               :disabled="userJson.tempAccount"
              @click="
                goDisinfectionRecord(scope.row.courseId, scope.row.auditState)
              "
            >
              {{ scope.row.auditState === "20" ? "撤销" : "提交" }}审核
            </el-button>
            <el-button
              style="padding: 0 5px"
              v-if="scope.row.enableLook"
              type="text"
               :disabled="userJson.tempAccount"
              size="mini"
              @click="ExportTimetable(scope.row.courseId)"
              >导出课表
            </el-button>
            <el-button
              style="padding: 0 5px"
              v-if="scope.row.auditState !== '20'&&scope.row.auditState !== '30'&&scope.row.courseOrigin == '10'"
              type="text"
              size="mini"
              @click="settingTeacher(scope.row.courseId)"
              >设置师资
            </el-button>
            <el-button
              style="padding: 0 5px"
              type="text"
              size="mini"
              @click="settingMenuStyle(scope.row)"
              >调整目录样式
            </el-button>
          </div>
        </el-table-column>
        <Empty slot="empty" />
      </el-table>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <CurriculumCopyPop ref="CurriculumCopyPop" @eventbtn="getData" />
    <!-- 自定义表头 -->
    <el-dialog
      title="自定义表头"
      :visible.sync="tableHeadDialog"
      width="30%"
      class="custom"
      @close="cencal"
    >
      <p style="padding-left: 20px; margin-bottom: 10px">
        勾选需要显示在列表上的项，可拖动调整顺序
      </p>
      <div>
        <el-tree
          ref="headTree"
          :data="treeDataCopy"
          default-expand-all
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
        >
          <template slot-scope="{ node, data }">
            <el-checkbox
              @change="
                (val) => {
                  handleCheckChange(val, data);
                }
              "
              v-model="data.checked"
              :disabled="data.label == '课程名称'"
              >{{ node.label }}</el-checkbox
            >
          </template>
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cencal">取 消</el-button>
        <el-button class="bgc-bv" @click="resetHeadOk">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 上架 -->
    <el-dialog
      title="上架"
      :visible.sync="shelfDialog"
      width="30%"
      class="custom"
    >
      <div>
        <p>你确定要将该课程发布到平台市场吗？</p>
        <el-radio v-model="radio" :label="true"
          >阅读并同意<el-button type="text" @click="jump"
            >《课程合作协议》</el-button
          ></el-radio
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="shelfcencal">取 消</el-button>
        <el-button class="bgc-bv" @click="shelfSure">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisibleCourseServer"
      width="800px"
    >
      <div>
        <iframe
          :src="src"
          frameborder="0"
          scrolling="auto"
          width="100%"
          height="400px"
        ></iframe>
      </div>
    </el-dialog>
    <el-dialog
      title="设置师资"
      :visible.sync="teacherData.dialogState"
      v-if="teacherData.dialogState"
      :before-close="()=>{teacherData = $options.data().teacherData}"
      width="600px"
    >
      <el-form ref="dialogForm" :model="teacherData" label-width="80px">
        <el-form-item label="培训类型">
          <tree
            ref="tree1"
            @eventBtn="childBack1"
            :ruleForm="teacherData"
            size="small"
            :modal="false"
            @clearParams="clearParams1"
            typeStu
          />
        </el-form-item>
        <el-form-item label="讲师">
          <el-select 
              v-model="teacherData.teacherId" 
              clearable
              filterable
              size="small"
              placeholder="请选择讲师">
            <el-option
              v-for="item in teachertree"
              :key="item.teacherQualificationId"
              :label="item.teacherQualificationName"
              :value="item.teacherQualificationId">
              <div style="display: flex;justify-content:space-between;align-items:center">
                <span>{{ item.teacherQualificationName }}</span>
                <el-popover
                  placement="right"
                  v-if="item.teacherQualificationId!= '-1000'"
                  trigger="hover">
                  <el-button type="text" slot="reference">证书</el-button>
                  <el-image
                    style="width: 500px; height: auto;"
                    fit="contain"
                    :src="item.qualificationImageUrl">
                  </el-image>
                </el-popover>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="teacherData = $options.data().teacherData">取 消</el-button>
          <el-button class="bgc-bv" @click="teacherSubmit">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog
      class="MenuStyle"
      title="调整目录样式"
      :visible.sync="menuStyleData.state"
      top="5%"
      width="1000px"
      @close="menuStyleData = $options.data().menuStyleData"
    >
      <div class="box">
        <el-form :model="menuStyleData" label-width="80px">
          <el-form-item label="课程名称:">{{menuStyleData.courseName}}</el-form-item>
          <el-form-item label="目录样式:">
            <el-radio-group class="img-radio" v-model="menuStyleData.chapterSchema">
              <el-radio :label="true"><img src="@/assets/style.png" alt="" /></el-radio>
              <el-radio :label="false"><img src="@/assets/style1.png" alt="" /></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>注:修改样式后，前往课程详情修改课程大纲！</el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="menuStyleData = $options.data().menuStyleData">取 消</el-button>
        <el-button class="bgc-bv" @click="menuStyleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import tree from "@/components/treePopup";
import PageNum from "@/components/PageNum.vue";
import CurriculumCopyPop from "@/views/resourse/popup/CurriculumCopyPop";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
    CurriculumCopyPop,
    tree,
  },
  mixins: [List],
  data() {
    return {
      showtable: false,
      queryStr: "",
      dialogVisible: false,
      dialogVisibles: false,
      ruleForm: {
        source: "",
        auditState: "",
        salesState: "",
        Trainingtype: "",
        Creattime: [],
      },
      activeName: "first",
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      params: {},
      // 自定义表头
      tableHeadDialog: false,
      treeData: [
        {
          label: "课时标准(分钟)", //表头名称
          propName: "convertClassHour", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 1,
          id: "14",
          checked: true,
        },
        {
          label: "总课时", //表头名称
          propName: "classHour", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "80", // 列的宽
          order: 1,
          id: "1",
          checked: true,
        },
        // {
        //   label: "总学时", //表头名称
        //   propName: "lessonNum", //后台对应字段名称
        //   textAlign: "right", //文字对齐方式e
        //   renderType: "", //渲染类型
        //   timeType: "", //时间字段的渲染类型
        //   dictionaries: "", //字典对应key
        //   width: "80", // 列的宽
        //   order: 2,
        //   id: "2",
        //   checked: true,
        // },
        {
          label: "课件数量", //表头名称
          propName: "kpointTotalNum", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 3,
          id: "3",
          checked: true,
        },
        {
          label: "视频总时长", //表头名称
          propName: "kpointTotalDuration", //后台对应字段名称
          textAlign: "right", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "custom", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 4,
          id: "4",
          checked: true,
        },
         {
          label: "培训类型", //表头名称
          propName: "trainTypeNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 5,
          id: "5",
          checked: true,
        },
        {
          label: "行业类型", //表头名称
          propName: "industryNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 6,
          id: "6",
          checked: true,
        },
        {
          label: "岗位类型", //表头名称
          propName: "postName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "160", // 列的宽
          order: 7,
          id: "7",
          checked: true,
        },
        {
          label: "职业/工种", //表头名称
          propName: "occupationNamePath", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 8,
          id: "8",
          checked: true,
        },
        {
          label: "培训等级", //表头名称
          propName: "trainLevelName", //后台对应字段名称
          textAlign: "left", //文字对齐方式e
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 9,
          id: "9",
          checked: true,
        },
        {
          label: "课程来源", //表头名称
          propName: "courseOrigin", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "COURSEORGIN", //字典对应key
          width: "100", // 列的宽
          order: 10,
          id: "10",
          checked: true,
        },
        {
          label: "审核状态", //表头名称
          propName: "auditState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "AUDITSTATE", //字典对应key
          width: "120", // 列的宽
          order: 11,
          id: "11",
          checked: true,
        },
        {
          label: "销售状态", //表头名称
          propName: "salesState", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "SELLSTATE", //字典对应key
          width: "120", // 列的宽
          order: 12,
          id: "12",
          checked: true,
        },
        {
          label: "创建时间", //表头名称
          propName: "createTime", //后台对应字段名称
          textAlign: "center", //文字对齐方式e
          renderType: "time", //渲染类型
          timeType: "moment", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "140", // 列的宽
          order: 13,
          id: "13",
          checked: true,
        },
      ],
      tableHaed: [],
      treeDataCopy: [],
      shelfDialog: false,
      radio: false,
      dialogVisibleCourseServer: false,
      src: "",
      title: "课程合作协议",
      // 设置师资弹窗
      teacherData:{
        dialogState:false,
        courseId:'',
        Trainingtype: "",
        params:{},
        teacherId:''
      },
      teachertree: [], // 讲师列表
      menuStyleData:{//调整目录弹窗数据
        state:false,
        courseId:'',
        courseName:'',
        chapterSchema:false,
      }
    };
  },
   computed: {
      ...mapGetters({
        userJson: "getUser"
      })
    },
  created() {
    this.getTableHead();
    this.getteachertree();
  },
  methods: {
    // 初始化方法
    init() {
      this.getData(-1);
      this.getTableHeight();
    },
    // 调整目录样式
    settingMenuStyle(row){
      this.menuStyleData = {
        state:true,
        courseId:row.courseId??'',
        courseName:row.courseName??'',
        chapterSchema:row.chapterSchema??false,
      }
    },
    // 调整目录样式 - 确定
    menuStyleSubmit(){
      this.$post("/biz/course/editChapterType", this.menuStyleData)
      .then(ret => {
        if (ret.status == 0) {
          this.menuStyleData = this.$options.data().menuStyleData
          this.getData(-1);
          this.$message({
            type: "success",
            message: "设置成功"
          });
        }
      })
    },
    // 设置师资
    settingTeacher(courseId){
      this.teacherData.dialogState = true
      this.teacherData.courseId = courseId
    },
    // 讲师列表
    getteachertree() {
      this.$post("/biz/teacher/queryQualificationDictionary", {}).then(res => {
        if (res.status == 0) {
         this.teachertree = [...res.data,{teacherQualificationId:'-1000',teacherQualificationName:'无讲师'}]
        }
      });
    },
    // 设置师资提交
    teacherSubmit(){
      let params = {
        courseId:this.teacherData.courseId,
        teacherQualificationId:this.teacherData.teacherId,
      }
      if(!this.teacherData.teacherId&&!this.teacherData.Trainingtype){
        this.$message.error('请最少选择一个填写！')
        return
      }
      // 培训类型 trainForm postForm occForm levelForm industryForm
      if (this.teacherData.params.trainFormId) {
        params.trainTypeId = this.teacherData.params.trainFormId;
      }
      if (this.teacherData.params.levelFormId) {
        params.trainLevelId = this.teacherData.params.levelFormId;
      }
      if (this.teacherData.params.occFormId) {
        params.occupationId = this.teacherData.params.occFormId;
      }
      if (this.teacherData.params.postFormId) {
        params.postId = this.params.teacherData.postFormId;
      }
      if (this.teacherData.params.industryFormId) {
        params.industryId = this.teacherData.params.industryFormId;
      }
      this.$post("/biz/course/setTeachers", params)
      .then(ret => {
        if (ret.status == 0) {
          this.teacherData = this.$options.data().teacherData
          this.getData(-1);
          setTimeout(() => {
            this.$message({
              type: "success",
              message: "设置成功"
            });
            
          }, 300);
        }
      })
      .catch(() => {
        return;
      });
    },
    // 根据字段转换页面展示的内容
    getValue(el, row) {
      // 状态
      if (el.renderType == "status") {
        if (el.propName == "auditState") {
          if (row.auditState == "30") {
            return "已通过";
          } else if (row.auditState == "40") {
            return "已驳回";
          } else {
            return this.$setDictionary(el.dictionaries, row[el.propName]);
          }
        } else if (el.propName == "salesState") {
          if (row.courseOrigin == "20") {
            return "--";
          } else {
            return this.$setDictionary(el.dictionaries, row[el.propName]);
          }
        } else {
          return this.$setDictionary(el.dictionaries, row[el.propName]);
        }
      } else if (el.renderType == "time") {
        // 时间
        if (el.timeType == "moment") {
          return row[el.propName] ? this.$moment(row[el.propName]).format("YYYY-MM-DD HH:mm:ss") : "--";
        } else if (el.timeType == "custom") {
          return this.getTime(row[el.propName]);
        } else {
          return row[el.propName];
        }
      } else {
        // 其他
        return row[el.propName] || "--";
      }
    },
    // 自定义确定
    resetHeadOk() {
      let list = [];
      let saveData = [];
      this.treeDataCopy.forEach((el, index) => {
        saveData.push({ id: el.id, order: index, checked: el.checked });
        if (el.checked) {
          list.push(el);
        }
      });
      this.tableHaed = list;
      this.tableHeadDialog = false;
      this.$post("/sys/admin/savePageConfig", {
        configInfo: JSON.stringify(saveData),
        pageName: "myCurriculum",
      }).then((res) => {
        this.getTableHead();
      });
    },
    // 自定义表头 - 打开弹框
    open() {
      this.tableHeadDialog = true;
      this.handle();
    },
    // 自定义表头 - 打开弹框 - 处理数据
    handle() {
      this.tableHaed = [];
      this.treeDataCopy = [];
      this.treeData.forEach((el) => {
        this.treeDataCopy.push(Object.assign({}, el));
      });
      this.treeDataCopy.forEach((el) => {
        if (el.checked) {
          this.tableHaed.push(el);
        }
      });
      this.showtable = true;
    },
    // 自定义表头 - 关闭弹框
    cencal() {
      this.tableHeadDialog = false;
    },
    // 获取自定义头部
    getTableHead() {
      this.$post("/sys/admin/findAdminConfig", {
        pageName: "myCurriculum",
      })
        .then((res) => {
          if (res.status == 0) {
            const data = res.data || {};
            let list = [];
            if (data.configInfo && data.configInfo != "") {
              list = JSON.parse(data.configInfo);
            }
            if (list.length) {
              list.map((el) => {
                this.treeData.forEach((it, index) => {
                  if (el.id == it.id) {
                    it.checked = el.checked;
                    it.order = el.order;
                  }
                });
              });
              this.treeData.sort((a, b) => {
                return a.order - b.order;
              });
              this.handle();
            } else {
              this.handle();
            }
          } else {
            // this.tableHaed = [...this.treeData];
            this.handle();
          }
        })
        .catch((err) => {
          this.tableHaed = [...this.treeData];
        });
    },
    // 判断拖拽的位置
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 节点是否可以拖拽
    allowDrag(draggingNode) {
      if (draggingNode.label == "课程名称") {
        return false;
      }
      return true;
    },
    handleCheckChange(val, data) {},
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    // 培训类型 - 数据处理
    clearParams() {
      this.params = {};
    },
    // 设置师资 - 培训类型回调
    childBack1(params) {
      this.teacherData.params = { ...params };
    },
    // 设置师资 - 培训类型 - 数据处理
    clearParams1() {
      this.teacherData.params = {};
    },
    // 我也没细看干啥的 - 有缘人瞅瞅吧（我懒）
    getList() {
      this.dialogVisible = false;
      if (this.getTraintype && this.getTraintype.length) {
        this.datatreeList = this.getTraintype;
        return;
      }
      this.$post("/sys/category/train-type/tree").then((ret) => {
        const retdata = ret.data || [];
        if (retdata.length) {
          this.datatreeList = retdata;
          this.$store.dispatch("handleTraintype", retdata);
        }
      });
    },
    // 获取列表数据
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.queryStr) {
        params.courseName = this.queryStr;
      }
      if (this.ruleForm.auditState) {
        params.auditState = this.ruleForm.auditState;
      }
      if (this.ruleForm.source) {
        params.courseOrigin = this.ruleForm.source;
      }
      if (this.ruleForm.salesState) {
        params.salesState = this.ruleForm.salesState;
      }
      if (this.ruleForm.Creattime) {
        params.startTime = this.ruleForm.Creattime[0];
        params.endTime = this.ruleForm.Creattime[1];
      }
      // 培训类型 trainForm postForm occForm levelForm industryForm
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      //

      this.doFetch({
        url: "/biz/course/listPage",
        params,
        pageNum,
      });
    },
    // 上传课件
    handleAddCourseware() {
      this.$router.push({ path: "/web/AddCourseware" });
    },
    // 创建课程
    handleAdd() {
      this.$router.push({ path: "/web/Createcourse" });
    },
    // 修改课程
    handleEdit(row) {
      let path = "/web/detailResource";
      // if (row.auditState === '10') {
      //   path = "/web/Createcourse";
      // } ----跳查看
      this.$router.push({
        path,
        query: {
          courseId: row.courseId,
          auditState: row.auditState,
          courseOrigin: row.courseOrigin,
          stu: "edits",
        },
      });
    },
    // 复制课程
    handleCopy(courseId) {
      this.$refs.CurriculumCopyPop.showPopUp(courseId);
    },
    // 删除
    handleDel(courseId) {
      this.doDel({
        url: "/biz/course/delete",
        msg: "你确定要删除该课程吗？",
        ps: {
          type: "post",
          data: { courseId: courseId },
        },
      });
    },
    // 提/撤 审核
    goDisinfectionRecord(courseId, stu) {
      if (stu === "20") {
        this.doDel({
          title: "撤销审核",
          url: "/biz/course/auditUndo",
          data: {
            courseId,
          },
          msg: "该课程已经提交审核，是否撤销审核？",
          ps: {
            type: "post",
            data: { courseId: courseId },
          },
        });
      } else {
        this.doDel({
          title: "提交审核",
          url: "/biz/course/auditSubmit",
          data: {
            courseId,
          },
          msg: "课程只有审核通过才可以使用，您是否提交课程审核？",
          ps: {
            type: "post",
            data: { courseId: courseId },
          },
        });
      }
    },
    // 上下架
    shelf(courseId, stu) {
      this.courseId = courseId;
      if (stu === "10") {
        this.shelfDialog = true;
        // this.doDel({
        //   title: "上架",
        //   url: "/biz/course/salesOn",
        //   msg: "你确定要将该课程发布到平台市场吗？",
        //   ps: {
        //     type: "post",
        //     data: { courseId: courseId }
        //   }
        // });
      } else {
        this.doDel({
          title: "下架",
          url: "/biz/course/salesOff",
          msg: "你确定要将该课程从平台市场下架吗？",
          ps: {
            type: "post",
            data: { courseId: courseId },
          },
        });
      }
    },
    // 上下架 - 取消
    shelfcencal() {
      this.shelfDialog = false;
      this.radio = false;
    }, 
    // 上下架 - 确定
    shelfSure() {
      if (!this.radio) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意",
        });
        return;
      } else {
        this.$post("/biz/course/salesOn", { courseId: this.courseId }).then(
          (ret) => {
            if (ret.status == 0) {
              this.$message({
                message: "上架成功",
                type: "success",
              });
              this.shelfcencal();
              this.getData();
            }
          }
        );
      }
    },
    // 高级搜索
    search() {
      this.dialogVisible = !this.dialogVisible;
      this.getTableHeight();
    },
    // 导出课表
    ExportTimetable(courseId) {
      this.$post("/run/course/export/mycourse", { courseId })
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 清空搜索条件
    cancelForm() {
      // this.$refs.tree.clear(); // 高级搜索关闭，清空培训类型数据
      this.ruleForm = {
        source: "",
        auditState: "",
        salesState: "",
        Trainingtype: "",
        Creattime: "",
      };
      this.queryStr = "";
      this.params = {};
    },
    // 时间处理 - 时分秒
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    // 驻留使用的清空方法
    clearSearch() {
      this.queryStr = "";
      this.ruleForm = {
        source: "",
        auditState: "",
        salesState: "",
        Trainingtype: "",
        Creattime: [],
      };
      this.params = {};
    },
    //课程服务协议
    jump() {
      this.dialogVisibleCourseServer = true;
      this.src = "https://anzhi.bjsrxx.com/banner/app_static/courseServe.html";
    },
    // 列表高度计算
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.dialogVisible == false
            ? 90 + 0.675 * 16 + 1
            : 120 + 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.searchbox {
  padding: 0;
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}

.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item {
      margin-bottom: 0;
    }
    .Creattime {
      display: flex;
      align-items: center;
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
.textRed {
  color: red;
}
.img-radio{
  display: flex;
  align-items: center;
  .el-radio{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    /deep/ .el-radio__label{
      margin-bottom: 20px;
    }
    img{
      width: 400px;
    }
  }
}
</style>
